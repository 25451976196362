import { Image } from "./image";
import React from "react";

export const Gallery = (props) => {
  return (
    <div
      id="portfolio"
      className="text-center"
      style={{ backgroundColor: "#ffff" }}
    >
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
          <p>
            Explore Gujjar Dairy Farm's gallery – a visual journey into the
            heart of our thriving farm. Dive into snapshots of joyful animals,
            lively play zones, and the scenic beauty that defines the unique
            charm of our agricultural haven.
          </p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};
